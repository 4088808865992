<template>
    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item :to="{ name: 'contact_registry' }"> {{$t('label_contact_register')}}
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t(curPageLabel)}}</b-breadcrumb-item>
            </div>
        </div>
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5 class="text-primary" v-if="itemData">{{itemData.contact_name}}</h5>
            </b-card-header>
            <b-card-body>
                <b-tabs content-class="mt-1">
                    <b-tab

                            @click="curPageLabel = $t('label_informations')"
                            :title="$t('label_informations')"

                    >
                        <b-row class="pt-1">

                            <b-col
                                    cols="12"
                                    md="6"
                                    class=" mb-1"
                            >

                                <b-table-simple class="table-details" responsive no-border-collapse>
                                    <b-tbody>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_category')}}</b-td>
                                            <b-td>
                                                {{itemData.category?itemData.category.name : ''}}
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_adding_date')}}</b-td>
                                            <b-td>
                                                {{itemData.contact_add_date? formatDate(itemData.contact_add_date,
                                                'DD.MM.YYYY', 'YYYY-MM-DD HH:mm:ss') : ''}}
                                            </b-td>
                                        </b-tr>

                                        <b-tr>
                                            <b-td width="40%">{{$t('label_contact_type')}}</b-td>
                                            <b-td>
                                                {{itemData.contact_type_name}}
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_specialization')}}</b-td>
                                            <b-td>
                                                {{itemData.contact_specialization}}
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_country')}}</b-td>
                                            <b-td>
                                                {{itemData.country ? itemData.country.countryName : ''}}
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_town')}}</b-td>
                                            <b-td>
                                                {{itemData.contact_city}}
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_street_and_house_number')}}</b-td>
                                            <b-td>
                                                {{itemData.contact_street}}
                                            </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td width="40%">{{$t('label_post_code')}}</b-td>
                                            <b-td>
                                                {{itemData.contact_zip}}
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </b-col>
                            <b-col
                                    cols="12"
                                    md="6"
                                    class=" mb-1"
                            >
                                <!--<b-table-simple bordered small striped responsive>-->
                                <b-table-simple class="table-details" responsive no-border-collapse>
                                    <b-tbody>
                                        <template v-if="itemData.contact_type == 1">
                                            <b-tr>
                                                <b-td width="40%">{{$t('label_name_of_company')}}</b-td>
                                                <b-td>
                                                    {{itemData.contact_company_name}}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td width="40%">{{$t('label_nip')}}</b-td>
                                                <b-td>
                                                    {{itemData.contact_nip}}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td width="40%">{{$t('label_krs')}}</b-td>
                                                <b-td>
                                                    {{itemData.contact_krs}}
                                                </b-td>
                                            </b-tr>

                                            <b-tr>
                                                <b-td width="40%">{{$t('label_name_and_surname')}}</b-td>
                                                <b-td>
                                                    {{itemData.contact_person}}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td width="40%">{{$t('label_email_address')}}</b-td>
                                                <b-td>
                                                    {{itemData.contact_person_email}}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td width="40%">{{$t('label_phone')}}</b-td>
                                                <b-td>
                                                    {{itemData.contact_person_phone}}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td width="40%">{{$t('label_position')}}</b-td>
                                                <b-td>
                                                    {{itemData.contact_person_position}}
                                                </b-td>
                                            </b-tr>
                                        </template>
                                        <template v-else>
                                            <b-tr>
                                                <b-td width="40%">{{$t('label_first_name')}}</b-td>
                                                <b-td>
                                                    {{itemData.contact_firstname}}
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td width="40%">{{$t('label_last_name')}}</b-td>
                                                <b-td>
                                                    {{itemData.contact_lastname}}
                                                </b-td>
                                            </b-tr>

                                        </template>

                                    </b-tbody>
                                </b-table-simple>


                            </b-col>
                        </b-row>
                    </b-tab>

                </b-tabs>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
    import {
        BCard,
        BCardBody,
        BCardHeader,
        BTableSimple,
        BTbody,
        BTr,
        BTd,
        BThead,
        BTh,
        BButton,
        BBreadcrumbItem,
        BTabs,
        BTab,
        BTable,
        BDropdown,
        BDropdownItem

    } from 'bootstrap-vue'
    import {CONTACT_REG_PREFIX as MODULE_PREFIX} from './moduleHelper'
    export default {
        components: {
            BCard,
            BCardBody,
            BCardHeader,
            BTableSimple,
            BTbody,
            BTr,
            BTd,
            BThead,
            BTh,
            BButton,
            BBreadcrumbItem,
            BTabs,
            BTab,
            BTable,
            BDropdown,
            BDropdownItem
        },
        // props:['itemData'],
        data() {
            return {
                MODULE_PREFIX,
                itemData: {},
                curPageLabel: 'label_informations',
            }
        },

        created() {
            let item_id = this.$router.currentRoute.params.id;

            this.async('get', this.MODULE_PREFIX+ '/' + item_id, {}, function(res){
                this.itemData = res.data
            });
        }

    }
</script>